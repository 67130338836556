import { Box, Button, Slide, Switch } from '@mui/material';
import DataTable from 'lib/DataTable';
import { useEffect, useState } from 'react';
import { getVideoSources, putVideoSourceUpdate } from 'services/AdminService';
import AddVideoSource from './AddVideoSource';

const videoSourcesColumns = [
    { id: 'id', name: 'ID' },
    { id: 'name', name: 'Name' },
    { id: 'url', name: 'URL' },
    { id: 'channelID', name: 'Channel ID' },
    { id: 'platform', name: 'Platform' },
    {
        id: 'scrapingActive',
        name: 'Scraping?',
        valueAccessor: (row) => {
            return (
                <Switch
                    defaultChecked={row.scrapingActive}
                    onChange={(e) => {
                        row.scrapingActive = e.target.checked;
                        updateScrapingActive(row, e.target.checked);
                    }}
                />
            );
        }
    }
];

const updateScrapingActive = (row, value) => {
    putVideoSourceUpdate({ ...row, scrapingActive: value }, (resp) => console.log(resp));
};

export default function VideoSources() {
    const [sources, setSources] = useState([]);
    const loadVideos = () => {
        getVideoSources((resp) => setSources(resp));
    };

    useEffect(() => {
        loadVideos();
    }, []);

    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                <h1>Video sources</h1> <AddVideoSource loadVideos={loadVideos} />
            </Box>
            <DataTable rows={sources} columns={videoSourcesColumns}></DataTable>
        </Box>
    );
}
