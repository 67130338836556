import { Container, Grid } from '@mui/material';
import Nav from './Nav';
import About from './About';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import * as reactRouterDom from 'react-router-dom';
import Team from './team/Team';
import Tournament from './tournament/Tournament';
import React from 'react';
import Hello from './home/Hello';
import Person from './person/Person';
import Footer from './Footer';
import Contact from './Contact';
import Search from './Search';
import RecentlyViewed from './RecentlyViewed';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { getSuperTokensRoutesForReactRouterDom } from 'supertokens-auth-react';
import { EmailPasswordAuth } from 'supertokens-auth-react/recipe/emailpassword';
import Popular from './Popular';
import PageNotFound from './404';
import Matchups from './Matchups';
import AdminRoute from 'lib/AdminRoute';
import Admin from './admin/Admin';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';
import ContentLayout from './ContentLayout';
import Users from './admin/Users';
import User from './admin/User';
import Collision from './collision/Collision';
import BugReport from './BugReport';
import BugReports from './admin/BugReports';
import Collisions from './admin/Collisions';
import CollisionDetails from './admin/CollisionDetails';
import Database from './admin/Database';
import Support from './Support';
import Bitcoin from './Bitcoin';
import Game from './Game';
import CollegeNatsQualifiers2023 from './articles/CollegeNats2023';
import VideoSources from './admin/VideoSources';
import VideoList from './admin/VideoList';
import Roster from './team/Roster';
import TeamVideos from './team/TeamVideos';
import RecentResults from './team/RecentResults';
import TeamRosters from './team/Rosters';
import MergeTeams from './admin/MergeTeams';
import RecentlyScraped from './admin/RecentlyScraped';
import UserProfileForm from './profile/UserProfile';
import VideoViewer from './VideoViewer';

const footerHeight = '64px';

export const darkModePalette = {
    primary: {
        main: '#FF889C',
        light: '#FF9CAA',
        dark: '#F97893'
    },
    accent: {
        main: '#8AD0E7'
    },
    paypal: {
        main: 'rgb(255, 188, 50)'
    },
    result: {
        win: '#5c95b5',
        loss: '#ad4c4c'
    },
    gender: {
        open: '#E13B5F',
        women: '#a0d9fa',
        mixed: '#AE5CF7',
        boys: '#f28e41',
        girls: '#375cf0'
    },
    actions: {
        hover: '#292929'
    }
};

export const lightModePalette = {
    primary: {
        main: '#913AED',
        light: '#AE5CF7',
        dark: '#7B31DB'
    },
    paypal: {
        main: 'rgb(255, 188, 50)'
    },
    accent: {
        main: '#2580B8'
    },
    result: {
        win: '#a0d9fa',
        loss: '#f9a0a0'
    },
    gender: {
        open: '#E13B5F',
        women: '#1B7FDD',
        mixed: '#913AED',
        boys: '#f28e41',
        girls: '#375cf0'
    },
    actions: {
        hover: '#E9E9E9'
    }
};

export const typographyConfig = {
    fontFamily: ['Mulish', 'sans-serif'].join(',')
};

function saveDarkModeToLocalStorage(darkMode) {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));
}

function loadDarkModeFromLocalStorage() {
    const darkMode = localStorage.getItem('darkMode');
    return darkMode ? JSON.parse(darkMode) : undefined;
}

export default function Main() {
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const stored = loadDarkModeFromLocalStorage();
    const defaultModeInitialSelection = stored !== undefined ? stored : prefersDarkMode;
    const [darkMode, setDarkMode] = useState(defaultModeInitialSelection);
    const [pageTitle, setPageTitle] = useState('');

    const toggleDarkMode = (darkMode) => {
        saveDarkModeToLocalStorage(darkMode);
        setDarkMode(darkMode);
    };

    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
            ...(darkMode ? darkModePalette : lightModePalette)
        },
        typography: typographyConfig
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Container
                    maxWidth="100vw"
                    // disableGutters
                    // width="100%"
                    sx={{ minHeight: `calc(100vh - ${footerHeight})` }}>
                    <Nav darkMode={darkMode} sx={{ width: '100%' }} setDarkMode={toggleDarkMode} />
                    <Routes>
                        {getSuperTokensRoutesForReactRouterDom(reactRouterDom)}
                        <Route
                            path="/"
                            element={<ContentLayout pageTitle={pageTitle}></ContentLayout>}>
                            <Route path="/" element={<Hello />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/support" element={<Support />} />
                            <Route path="/bitcoin" element={<Bitcoin />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route
                                path="/college-nats-qualifiers"
                                element={<CollegeNatsQualifiers2023 />}
                            />
                            <Route path="/matchup" element={<Matchups />} />
                            {/* <Route path="/results" element={<Search isResultsPanel={true} />} /> */}
                            <Route path="/team/:slug/" element={<Team setName={setPageTitle} />}>
                                <Route path="roster/" element={<TeamRosters />}>
                                    <Route path=":rosterId" element={<Roster />} />
                                </Route>

                                <Route path="results" element={<RecentResults />} />
                                <Route path="film" element={<TeamVideos />} />
                            </Route>
                            <Route
                                path="/person/:slug/"
                                element={<Person setName={setPageTitle} />}></Route>
                            <Route
                                path="/tournament/:slug"
                                element={<Tournament setName={setPageTitle} />}
                            />
                            <Route path="/game/:id" element={<Game setName={setPageTitle} />} />
                            <Route path="/popular" element={<Popular />} />
                            <Route
                                path="collisions/:slug"
                                element={
                                    <SessionAuth>
                                        <Collision />
                                    </SessionAuth>
                                }
                            />
                            <Route
                                path="/bug-report"
                                element={
                                    <SessionAuth>
                                        <BugReport />
                                    </SessionAuth>
                                }
                            />
                            <Route
                                path="/admin/"
                                element={
                                    <AdminRoute>
                                        <Admin />
                                    </AdminRoute>
                                }>
                                <Route path="users" element={<Users />} />
                                <Route path="user/:id" element={<User />} />
                                <Route path="collisions" element={<Collisions />} />
                                <Route path="collisions/:id" element={<CollisionDetails />} />
                                <Route path="bug-reports" element={<BugReports />} />
                                <Route path="service-health" element={<Database />}></Route>
                                <Route path="merge-teams" element={<MergeTeams />}></Route>
                                <Route path="video-sources" element={<VideoSources />}></Route>
                                <Route path="videos" element={<VideoList />}></Route>
                                <Route
                                    path="recently-scraped"
                                    element={<RecentlyScraped />}></Route>
                            </Route>
                            <Route path="/profile" element={<UserProfileForm />} />
                            <Route path="/video/:id" element={<VideoViewer />} />
                        </Route>

                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </Container>
                <Container
                    maxWidth="xl"
                    sx={{ height: footerHeight, display: 'flex', justifyContent: 'center' }}>
                    <Footer />
                </Container>
            </Router>
        </ThemeProvider>
    );
}
