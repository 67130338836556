import { Button, Grid, Link, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { sessionInfo } from 'services/AdminService';

const tabs = [
    { label: 'Data', to: 'service-health' },
    { label: 'Users', to: 'users' },
    { label: 'Collisions', to: 'collisions' },
    { label: 'Bug Reports', to: 'bug-reports' },
    { label: 'Recently scraped', to: 'recently-scraped' },
    { label: 'Video sources', to: 'video-sources' },
    { label: 'Videos', to: 'videos' }
];

export default function Admin() {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    let location = useLocation();

    useEffect(() => {
        const index = tabs.findIndex((tab) => tab.to === location.pathname.split('/')[2]);
        setValue(index === -1 ? 0 : index);
    }, [location]);

    const handleChange = (event, newValue) => {
        navigate(event.target.getAttribute('to'));
        setValue(newValue);
    };

    return (
        <Box>
            <Tabs
                value={value}
                onChange={handleChange}
                sx={{ mb: 2, borderBottom: '1px solid grey' }}>
                {tabs.map((tab) => (
                    <Tab key={tab.to} label={tab.label} to={tab.to} />
                ))}
            </Tabs>

            <Outlet></Outlet>
        </Box>
    );
}
